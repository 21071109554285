import { createRouter, createWebHistory } from "vue-router"
import useSessionStore from "@/stores/sessionStore.js"
import LoginView from "@/views/LoginView.vue"
import RequestPasswordChangeView from "@/views/RequestPasswordChangeView.vue"
import SetPasswordView from "@/views/SetPasswordView.vue"
import NewsView from "@/views/NewsView.vue"
import DashboardView from "@/views/DashboardView.vue"
import ResultsView from "@/views/ResultsView.vue"
import AssetsView from "@/views/AssetsView.vue"
import AssetsInformationView from "@/views/assetsTabs/AssetsInformationView.vue"
import AssetsAnalysisView from "@/views/assetsTabs/AssetsAnalysisView.vue"
import AssetsExpectedRevenueView from "@/views/assetsTabs/AssetsExpectedRevenueView.vue"
import AssetsServicesView from "@/views/assetsTabs/AssetsServicesView.vue"
import MarketsView from "@/views/MarketsView.vue"
import LogoutView from "@/views/LogoutView.vue"
import AccountView from "@/views/AccountView.vue"
import AccountInvoicesView from "@/views/accountTabs/AccountInvoicesView.vue"
import AccountSettingsView from "@/views/accountTabs/AccountSettingsView.vue"
import ChangeEmailView from "@/views/ChangeEmailView.vue"
// import MarketOrdersView from "@/views/MarketOrdersView.vue"

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    alias: "/welcome",
    component: RequestPasswordChangeView,
  },
  {
    path: "/set-password",
    name: "set-password",
    alias: "/change-password",
    component: SetPasswordView,
  },
  {
    path: "/news",
    alias: "/contact",
    name: "news",
    component: NewsView,
  },
  {
    path: "/dashboard",
    alias: "/",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/results",
    name: "results",
    component: ResultsView,
  },
  // {
  //   path: "/market-orders",
  //   name: "market-orders",
  //   component: MarketOrdersView,
  // },
  {
    path: "/assets",
    name: "assets",
    component: AssetsView,
    redirect: { name: "assets-information" },
    children: [
      {
        path: "information",
        name: "assets-information",
        component: AssetsInformationView,
      },
      {
        path: "analysis",
        name: "assets-analysis",
        component: AssetsAnalysisView,
      },
      {
        path: "services",
        name: "assets-services",
        component: AssetsServicesView,
      },
      {
        path: "expected-revenue",
        name: "assets-expected-revenue",
        component: AssetsExpectedRevenueView,
      },
    ],
  },
  {
    path: "/markets",
    name: "markets",
    component: MarketsView,
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
    redirect: { name: "account-settings" },
    children: [
      {
        path: "settings",
        name: "account-settings",
        component: AccountSettingsView,
      },
      {
        path: "invoices",
        name: "account-invoices",
        component: AccountInvoicesView,
      },
    ],
  },
  {
    path: "/change-email",
    name: "change-email",
    component: ChangeEmailView,
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "dashboard" },
  },
]

export const onlyPublicRoutes = ["login", "forgot-password", "set-password"]
export const publicRoutes = [...onlyPublicRoutes, "logout"]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to) => {
  const sessionStore = useSessionStore()
  // Don't redirect if session status is unknown
  if (!sessionStore.unknown) {
    // Redirect to login if unauthorized
    if (!publicRoutes.includes(to.name) && !sessionStore.isAuthenticated) {
      return { name: "login" }
    }
    // Redirect to dashboard if already logged in
    if (onlyPublicRoutes.includes(to.name) && sessionStore.isAuthenticated) {
      return { name: "dashboard" }
    }
  }
})

export default router
