<template>
  <div class="flex flex-col gap-4">
    <!-- Header -->
    <TextHeader>Assets</TextHeader>
    <TabsComponent :tabs />
    <ToolbarComponent
      :assets-filter="assetsFilter"
      no-allocation-points
      uncollapsed
      no-mode
      :no-datepicker="routesWithoutDatePicker.includes($route.name)"
    >
      <ExportComponent
        v-if="$route.name === 'assets-analysis'"
        :metering-point="filterStore.currentMeteringPoint"
        :date-range="filterStore.dateRange"
        type="measurements"
      />
    </ToolbarComponent>
    <MessageComponent
      v-if="filterStore.currentMeteringPoint?.demo"
      color="info"
    >
      {{ $p("demo-disclaimer") }}
    </MessageComponent>
    <RouterView />
  </div>
</template>

<script setup>
import { computed, onBeforeMount } from "vue"
import { startOfToday } from "date-fns"
import { useRoute } from "vue-router"
import $p from "@/services/phraseService.js"
import { TextHeader } from "@repowerednl/ui-component-library"
import useFilterStore from "@/stores/filterStore.js"
import TabsComponent from "@/components/navigation/TabsComponent.vue"
import ToolbarComponent from "@/components/navigation/ToolbarComponent.vue"
import ExportComponent from "@/components/information/ExportComponent.vue"
import MessageComponent from "@/components/information/MessageComponent.vue"

const filterStore = useFilterStore()
const route = useRoute()

const tabs = computed(() => {
  const baseTabs = [
    {
      name: "Info",
      icon: "information",
      route: "assets-information",
    },
    {
      name: "Analyse",
      icon: "history",
      route: "assets-analysis",
    },
    {
      name: "Diensten",
      icon: "stack",
      route: "assets-services",
    },
  ]
  if (
    !filterStore.currentMeteringPoint ||
    filterStore.currentMeteringPoint?.type === "Battery"
  ) {
    baseTabs.push({
      name: "Verwachte omzet",
      icon: "money-euro-circle",
      route: "assets-expected-revenue",
    })
  }
  return baseTabs
})

const routesWithoutDatePicker = ["assets-information", "assets-services"]

const assetsFilter = computed(() => {
  return route.name === "assets-expected-revenue"
    ? (asset) => asset.type === "Battery"
    : null
})

onBeforeMount(() => {
  filterStore.currentMeteringPoint = null
  filterStore.dateRange = [startOfToday(), startOfToday()]
})
</script>
